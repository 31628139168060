import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "color"
    }}>{`Color`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--link`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$link-01`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "interactive-states"
    }}>{`Interactive states`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$hover-primary-text`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`:active`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$text-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`:focus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$focus`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`:visited`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$visited-link`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`:disabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$disabled-02`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "57.472826086956516%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAABlElEQVQoz42RP0/CQBjGj/vTJiqK8TM5urj4IdxgIoQQJk1YCIzEmU32tmGAwYCJMUBp6V2pmJpUkzrVgam+bWwjUohP8uZ9r717+vx6CIFqtRpKVC6X5VarxcIwzOn6HC9tjh+eHayMVthYCGwLjjkXUBwLIXJQ8bmkx2q32+lcr9dPOp3O0Xq9pqZpSGAihYEOH3iir47FLC4kITgDQ+iCQo/PJX1DhUIBZeo8lNBlmEc7tGVWrVbTuVgsHjabTRmQ8WQ2J/7bAjd6Hr2+8yVAJyuHY0hJwIRAQpyJrKpqOne7XVnTNNl1XcCy2Hwh2NeHAciPxFnyCJn9IEdFE6OtlJAI7dRFeICuwjPYlfl6I12kSqXyG/m4VCqdBkFATNMi7suC3A9d2ui9s6lhE7h1wE2RSSbyaDRKUw4GAzocDiXbtqkAJECkK6GzT3cGqBZgCsCMUdneW/Z9fyexoiiyqmn5m5vb/92yrutx9zwPTSYTNJ1OY4Roo+M4yLIsNB6P43XyPOmJ2dZ/3KV+v793/VffyakkrHnWBtgAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Example of enabled, hover, and disabled link states",
          "title": "Example of enabled, hover, and disabled link states",
          "src": "/static/595189de8a1ae1f0e2f7b2e1067e7a20/fb070/link-style-1.png",
          "srcSet": ["/static/595189de8a1ae1f0e2f7b2e1067e7a20/d6747/link-style-1.png 288w", "/static/595189de8a1ae1f0e2f7b2e1067e7a20/09548/link-style-1.png 576w", "/static/595189de8a1ae1f0e2f7b2e1067e7a20/fb070/link-style-1.png 1152w", "/static/595189de8a1ae1f0e2f7b2e1067e7a20/c3e47/link-style-1.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">Normal, hover, disabled link states</Caption>
    <h2 {...{
      "id": "typography"
    }}>{`Typography`}</h2>
    <p>{`Links should not exceed three words.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-size (px/rem)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-weight`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Text style`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--link`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14 / 0.875`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$body-short-01`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "structure"
    }}>{`Structure`}</h2>
    <h3 {...{
      "id": "recommended"
    }}>{`Recommended`}</h3>
    <p>{`Links can be grouped horizontally or vertically and must be underlined. The following specs are not built into the Link component but are recommended by design as the proper distance between grouped Links.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`px / rem`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Spacing token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--link`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "54.891304347826086%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAABtUlEQVQoz2NgQALx8fEM6OD///8Mly5dYrh37x7DnTt3sGIQgNFwUFBQwEAuwDBs6tSpDL6+vmB2W1sbb2trq3BvTw9/U3OL8LSZc3ievnjLev7yDbYr128D8V22O3dus92+fQdI32HF6sLXr18z2NnZgdmnT59mPHLoIOPOA8cZ/9+czAjyMipuYbh64x7j3Tu3GYGGMN6+fRu7K0EgNDQUVUDlP5vRgkfyFsseyuvNeSDtsPq5AoPcf3Z0fTBD4YCNjY0hISEBzJaXl2cUlldmlAJxXCYyMzdN52dqn8HL0DyDm617KSdRYbhw4UIGf39/MLujo4O3q6tTqKu7V2BiV73g/6ebWP///8Py/+0j1v9vHrI+uXuF7fbNW6DwA4bjbVasXgaFjaurK9z5h05dA4bdKgbdyrcs2kWv2XY9PQ9SxJALDcfHQHz78jWGO/fu4k42IBAcHAxhSPyH0En/xbnjvqq+kvHnvKCawntBJZn7IggrJrLh9fLu3bvhyWbGjBkMIpkfoQb+YwNiLpDxZ7SzGC9oZTBc1EpnvKiTAZa+VjcPvwtRQNJ/vNIX1VOxigMAvefyz+UPkU0AAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Link structure and spacing measurements",
          "title": "Link structure and spacing measurements",
          "src": "/static/4664edf33f91f9d6f316aa777f89b7df/fb070/link-style-2.png",
          "srcSet": ["/static/4664edf33f91f9d6f316aa777f89b7df/d6747/link-style-2.png 288w", "/static/4664edf33f91f9d6f316aa777f89b7df/09548/link-style-2.png 576w", "/static/4664edf33f91f9d6f316aa777f89b7df/fb070/link-style-2.png 1152w", "/static/4664edf33f91f9d6f316aa777f89b7df/c3e47/link-style-2.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">Structure and spacing measurements for Link | px / rem</Caption>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      